
 import TopBar from '@/components/TopBar.vue';
 import Footer from '@/components/Footer.vue';
 import { Options, Vue } from 'vue-class-component';

 @Options({
     components: {
         TopBar,
         Footer,
     },
 })
 export default class Home extends Vue {
 }

