
 import axios from 'axios';
 import { defineComponent } from 'vue';
 import TopBarTopicButton from '@/components/TopBarTopicButton.vue';

 export default defineComponent({
     components: {
         TopBarTopicButton,
     },
     data() {
         return {
             mobileMenuOpen: false,
             search: '',
             topics: [],
             error: ''
         }
     },
     methods: {
         formSubmit() {
             //this.$router.push({ name: "Search", params: { q: this.search } })
             console.log("Not implemented")
         },
         getTopics() {
             axios.get('https://jomo.news/api/topics')
             .then((resp: any) => {
                 this.topics = JSON.parse(JSON.stringify(resp.data))
             })
             .catch((err: any) => {
                 console.error("An error occured getting article topics.")
             })
         }
     },
     mounted() {
         this.getTopics()
     }
 })
