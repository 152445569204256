<template>
    <!-- TODO: Re-add click link -->
    <div class="md:w-auto bg-white block border-b-2 md:mx-2 md:border-0 border-gray-100">
        <button class="w-full hover:underline text-bold text-xl my-4">{{ name }}</button>
    </div>
</template>

<style scoped>
 button:first-letter {
     text-transform: capitalize;
 }
</style>

<script lang="ts">
 import { defineComponent } from 'vue';

 export default defineComponent({
     props: {
         name: {
             type: String,
             required: true,
         },
         link: {
             type: String,
             required: true,
         },
         param: {
             type: Number,
             required: false,
         }
     },
     methods: {
         goToLink(): void {
             if (this.$props.param !== undefined) {
                 this.$router.push({ name: this.$props.link, params: { tid: this.$props.param } });
             } else {
                 this.$router.push({ name: this.$props.link });
             }
         }
     }
 })
</script>
