<template>
    <section class="mx-4 my-4" @click="gotoArticle"> <!-- REMOVE h-64 later -->
        <div v-if="!this.$props.article.image.error" class="bg-gray-800 overflow-hidden cursor-pointer w-full h-64 block ">
            <img v-if="this.$props.article.image.error !== true" class="mx-auto object-contain h-full" :src="'https://jomo.news/api/' + this.$props.article.image.large.url">
        </div>
        <div v-else class="cursor-pointer w-full h-64 block bg-green-200">
        </div>
        <p class="w-full block my-2 font-bold text-red-500 text-md">
            {{ this.$props.article.topic.toUpperCase() }}
        </p>
        <p class="cursor-pointer hover:underline w-full block text-3xl font-bold">
            {{ this.$props.article.title }}
        </p>
        <p class="w-full hover:underline mt-2 block text-md">
            {{ this.$props.article.author }}
        </p>
    </section>
</template>

<script lang="ts">
 import { defineComponent } from 'vue';
 import setTitleToURL from '@/utils';
 import { Article } from '@/types';

 export default defineComponent({
     name: 'MainArticle',
     props: {
         article: {
             type: Object as () => Article,
             required: true
         }
     },
     methods: {
         gotoArticle(): void {
             this.$router.push({ name: 'article', params: { aid: this.$props.article.id }})
         }
     }
 })
</script>
