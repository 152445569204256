<template>
  <div class="flex flex-col justify-between h-screen">
    <TopBar class="z-10"/>
    <router-view class="mb-auto" />
    <Footer />
  </div>
</template>

<script lang="ts">
 import TopBar from '@/components/TopBar.vue';
 import Footer from '@/components/Footer.vue';
 import { Options, Vue } from 'vue-class-component';

 @Options({
     components: {
         TopBar,
         Footer,
     },
 })
 export default class Home extends Vue {
 }

</script>
