
 import { defineComponent } from 'vue';
 import axios from "axios";
 import MainArticle from '@/components/HomeArticles/MainArticle.vue';
 import SideArticle from '@/components/HomeArticles/SideArticles.vue';
 //import PastArticle from '@/components/HomeArticles/PastArticles.vue';
 import { Article } from '@/types';

 //import * as article_import from '@/testarticles.json';

 interface MonthYear {
     month: string,
     year: string
 }

 export default defineComponent({
     name: "Home",
     components: {
         MainArticle,
         SideArticle,
         //PastArticle,
     },
     data: () => ({
         loading: true,
         articles: [] as Article[],
     }),
     methods: {
         isPastDate(month: number, year: number) {
             const currentDate = new Date()
             return month < currentDate.getUTCMonth() || year < currentDate.getUTCFullYear()
         },
         parseDate(dateString: string): MonthYear {
             const splitString = dateString.split('/')
             return { month: splitString[1], year: splitString[2] }
         },
         getArticles() {
             axios.get("https://jomo.news/api/articles")
             .then((resp: any) => {
                 this.loading = false
                 this.articles = this.parseArticles(JSON.parse(JSON.stringify(resp.data)))
             }).catch((err: any) => {
                 console.error("Error getting articles: " + err)
             })
         },
         parseArticles(pulled: any): Article[] {
             const parsed: Article[] = pulled.map((a: any) => {
                 return {
                     id: a.id,
                     title: a.title,
                     author: a.author,
                     date: a.date,
                     body: a.body,
                     topic: a.topic.name,
                     article_type: a.article_type.name,
                     image: (a.image !== null) ? a.image.formats : { error: true }
                 }
             })
             return parsed
         }
     },
     computed: {
         sideArticles(): Article[] {
             //return this.articles.slice(1)
             return this.articles.filter((a: Article) => {
                 return a.article_type === "regular"
             })
         },
         headerArticles(): Article[] {
             return this.articles.filter((a: Article) => {
                 return a.article_type === "header"
             })
         },
         // pastArticles(): Article[] { -->
         // return this.articles.filter((art: Article) => {
         // const parsed = this.parseDate(art.date);
         // return this.isPastDate(Number(parsed["month"]), Number(parsed["year"]))
         // })
         // }
     },
     mounted() {
         this.getArticles()
     }
 })
