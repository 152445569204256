<template>
    <section class="md:flex md:h-32 m-4" @click="gotoArticle"> <!-- REMOVE h-64 later -->
        <div v-if="this.$props.article.image.error !== true" class="cursor-pointer w-full md:w-1/3 md:flex-none h-full block md:inline-block">
            <img class="object-contain w-11/12" :src="'https://jomo.news/api/' + this.$props.article.image.thumbnail.url">
        </div>
        <div v-else class="w-full cursor-pointer flex-none h-32 md:h-full mx-auto w-2/3 md:w-1/3 block bg-red-200">
        </div>
        <div class="flex-auto h-full block md:inline-block ml-2">
            <p class="w-full block my-2 font-bold text-red-500 text-md">
                {{ this.$props.article.topic.toUpperCase() }}
            </p>
            <p class="cursor-pointer hover:underline w-full block text-xl font-bold overflow-hidden">
                {{ this.$props.article.title }}
            </p>
            <p class="w-full hover:underline mt-2 block text-md">
                {{ this.$props.article.author }}
            </p>
        </div>
    </section>
</template>

<script lang="ts">
 import { defineComponent } from 'vue';
 import { Article } from '@/types';
 import setTitleToURL from '@/utils';

 export default defineComponent({
     name: 'SideArticle',
     props: {
         article: {
             type: Object as () => Article,
             required: true
         }
     },
     methods: {
         gotoArticle(): void {
             this.$router.push({ name: 'article', params: { aid: this.$props.article.id }})
         }
     }
 })
</script>
