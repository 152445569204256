<template>
    <div class="w-11/12 mx-auto">
        <!-- MAIN ARTICLES AND SUB-MAIN ARTICLES -->
        <section v-if="!this.loading" class="mb-8 container mx-auto w-100 md:grid md:gap-2 md:grid-cols-2 md:grid-auto-rows-min">
            <MainArticle class="row-span-3 col-span-1" :article="this.articles[0]" />
            <SideArticle class="col-span-1 row-span-1" v-for="article in sideArticles" :key="article.id" :article="article" />
        </section>
        <section v-else class="mb-8 container mx-auto w-100 md:grid md:grid-cols-2">
            <svg class="h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
        </section>
        <div class="w-full border-b-4 border-gray-800 mb-8"></div>
        <!-- PAST ARTICLES -->
        <p class="text-2xl w-full text-center mb-8">Past Articles</p>
        <section v-if="-1 > 0" class="container mx-auto w-100 md:grid md:grid-cols-3">
            <!-- <PastArticle v-for="article in pastArticles" :key="'past-' + article.id" :article="article"/> -->
            <!-- <PastArticle v-for="article in pastArticles" :key="'past-' + article.id" :article="article"/> -->
        </section>
        <section class="container mx-auto w-100 text-center">
            <p class="text-lg">No past articles to show.</p>
        </section>
    </div>
</template>

<script lang="ts">
 import { defineComponent } from 'vue';
 import axios from "axios";
 import MainArticle from '@/components/HomeArticles/MainArticle.vue';
 import SideArticle from '@/components/HomeArticles/SideArticles.vue';
 //import PastArticle from '@/components/HomeArticles/PastArticles.vue';
 import { Article } from '@/types';

 //import * as article_import from '@/testarticles.json';

 interface MonthYear {
     month: string,
     year: string
 }

 export default defineComponent({
     name: "Home",
     components: {
         MainArticle,
         SideArticle,
         //PastArticle,
     },
     data: () => ({
         loading: true,
         articles: [] as Article[],
     }),
     methods: {
         isPastDate(month: number, year: number) {
             const currentDate = new Date()
             return month < currentDate.getUTCMonth() || year < currentDate.getUTCFullYear()
         },
         parseDate(dateString: string): MonthYear {
             const splitString = dateString.split('/')
             return { month: splitString[1], year: splitString[2] }
         },
         getArticles() {
             axios.get("https://jomo.news/api/articles")
             .then((resp: any) => {
                 this.loading = false
                 this.articles = this.parseArticles(JSON.parse(JSON.stringify(resp.data)))
             }).catch((err: any) => {
                 console.error("Error getting articles: " + err)
             })
         },
         parseArticles(pulled: any): Article[] {
             const parsed: Article[] = pulled.map((a: any) => {
                 return {
                     id: a.id,
                     title: a.title,
                     author: a.author,
                     date: a.date,
                     body: a.body,
                     topic: a.topic.name,
                     article_type: a.article_type.name,
                     image: (a.image !== null) ? a.image.formats : { error: true }
                 }
             })
             return parsed
         }
     },
     computed: {
         sideArticles(): Article[] {
             //return this.articles.slice(1)
             return this.articles.filter((a: Article) => {
                 return a.article_type === "regular"
             })
         },
         headerArticles(): Article[] {
             return this.articles.filter((a: Article) => {
                 return a.article_type === "header"
             })
         },
         // pastArticles(): Article[] { -->
         // return this.articles.filter((art: Article) => {
         // const parsed = this.parseDate(art.date);
         // return this.isPastDate(Number(parsed["month"]), Number(parsed["year"]))
         // })
         // }
     },
     mounted() {
         this.getArticles()
     }
 })
</script>
