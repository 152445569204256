
 import { defineComponent } from 'vue';
 import { Article } from '@/types';
 import setTitleToURL from '@/utils';

 export default defineComponent({
     name: 'SideArticle',
     props: {
         article: {
             type: Object as () => Article,
             required: true
         }
     },
     methods: {
         gotoArticle(): void {
             this.$router.push({ name: 'article', params: { aid: this.$props.article.id }})
         }
     }
 })
