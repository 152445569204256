<template>
    <div class="w-full">
        <div class="mb-8 w-11/12 mx-auto h-32 border-b-4 border-gray-800">
            <!-- Holds the logo and 'sign in' buttons, along with the social media links -->
            <div class="w-full h-16 border-b border-gray-300 flex justify-between items-stretch">
                <!-- LOGO GOES HERE -->
                <div class="flex-none flex">
                    <div class="bg-red-500 rounded-full h-8 w-8 mx-4 my-auto"></div>
                    <p class="hidden md:inline text-xl text-gray-700 font-bold my-auto">JOMO</p>
                </div>

                <!-- Search bar aka. 'space filler' -->
                <input @keyup.enter="formSubmit" v-model="search" type="text" class="hidden md:inline xl:mx-64 flex-1 my-auto w-full border border-gray-300 rounded-full h-8 my-auto px-4" placeholder="Search...">

                <!-- Sign in & social media links -->
                <div class="flex-none my-auto mx-4">
                    <!-- <p class="">Sign In</p> -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                    </svg>
                </div>
            </div>

            <!-- Holds the main topics navigation buttons -->
            <!-- <div class="w-full h-16 flex px-8 gap-x-6"> -->
            <button @click="mobileMenuOpen = !mobileMenuOpen" class="mx-auto text-center mt-4 md:hidden w-full text-gray-600 p-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto my-auto w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>
            <nav class="bg-white z-20 w-full shadow-md md:shadow-none black md:flex-grow md:flex items-center md:w-auto" :class="mobileMenuOpen ? 'block': 'hidden'">
                <input @keyup.enter="formSubmit" v-model="search" type="text" class="w-full bg-white md:hidden border border-gray-300 h-16 my-auto px-4 text-xl" placeholder="Search...">
                <TopBarTopicButton name="Home" link="home" />
                <TopBarTopicButton v-for="tpc in this.topics" :key="tpc.id" :name="tpc.name" link="topic" :param="tpc.id" />
                    <!-- <TopBarTopicButton name="News" link="topic" param="1" /> -->
                    <!-- <TopBarTopicButton name="Research" link="topic" param="2" /> -->
                    <!-- <TopBarTopicButton name="Medicine" link="topic" param="3" /> -->
            </nav>
        </div>
    </div>
</template>

<script lang="ts">
 import axios from 'axios';
 import { defineComponent } from 'vue';
 import TopBarTopicButton from '@/components/TopBarTopicButton.vue';

 export default defineComponent({
     components: {
         TopBarTopicButton,
     },
     data() {
         return {
             mobileMenuOpen: false,
             search: '',
             topics: [],
             error: ''
         }
     },
     methods: {
         formSubmit() {
             //this.$router.push({ name: "Search", params: { q: this.search } })
             console.log("Not implemented")
         },
         getTopics() {
             axios.get('https://jomo.news/api/topics')
             .then((resp: any) => {
                 this.topics = JSON.parse(JSON.stringify(resp.data))
             })
             .catch((err: any) => {
                 console.error("An error occured getting article topics.")
             })
         }
     },
     mounted() {
         this.getTopics()
     }
 })
</script>
