
 import { defineComponent } from 'vue';

 export default defineComponent({
     props: {
         name: {
             type: String,
             required: true,
         },
         link: {
             type: String,
             required: true,
         },
         param: {
             type: Number,
             required: false,
         }
     },
     methods: {
         goToLink(): void {
             if (this.$props.param !== undefined) {
                 this.$router.push({ name: this.$props.link, params: { tid: this.$props.param } });
             } else {
                 this.$router.push({ name: this.$props.link });
             }
         }
     }
 })
