<template>
    <footer class="w-full footer bg-white relative pt-1 border-b-2 border-gray-700">
        <div class="mx-auto w-11/12">
            <div class="mt-16 border-t-4 border-gray-800 flex flex-col items-center">
                <div class="sm:w-2/3 text-center py-6">
                    <p class="text-sm text-gray-700 font-bold mb-2">
                        © 2020 Jomo
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
 import { defineComponent } from 'vue';

 export default defineComponent({
     name: 'Footer',
 })
</script>
